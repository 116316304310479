'use client'

import {
  useColorModeValue,
  Box,
  Image
} from '@chakra-ui/react';


export default function LogoDescription() {

  return (
    <Box 
      display={{ base: "none", md: "block" }} 
      position="absolute" 
      top={{ base: "20px", md: "10px" }} 
      left={{ base: "50%", md: "60px" }} 
      transform={{ base: "translateX(-50%)", md: "none" }} 
      p={2}
      zIndex="-1" // Set a low zIndex to position it behind other elements
    >
      <Image 
        src={useColorModeValue("logo_with_description.png", "logo_with_description_dark.png")} 
        alt="Logo" 
        width={{ base: "600px", md: "300px" }} 
      />
    </Box>
  );
}
