import { useEffect } from "react";
import {
  ChakraProvider,
  theme,
  Heading,
  Box,
  Text,
  Grid,
//   Stack,
//   Flex,
//   IconButton,
//   Divider,
  Link,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
// import { GrDocumentText } from "react-icons/gr";
import ReactGA from 'react-ga4';

export const UseCasesPage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/test_cases", title: "TestCasesPage" });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Box p={4}>
        <Heading as="h1" size="4xl" color="teal" textAlign="center" mb={{ base: 20, md: 20 }} mt={10}>
          Use Cases
        </Heading>

        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={8}>
          <Box ml={{ base: 2, md: 20 }} mr={{ base: 2, md: 20 }} 
          // border={"2px solid red"}
          >
            <Heading as="h3" size="lg" mb={8} textAlign={"center"} color={"blue"}>
            Precise Legal Resolutions in Record Time
            </Heading>
            <Text mb={4}>
            JudgeAI demonstrated its groundbreaking efficiency by resolving a complex legal dispute in just 5 minutes—a case that took a human judge 1.5 years and 12 court hearings to decide. 
            </Text>
            <Text mb={4}>
            The dispute involved a claim for damages related to an underground cable allegedly damaged by the defendant during excavation work. The plaintiff presented circumstantial evidence to support their claim, while the defendant denied the allegations and provided their own counter-evidence.
            </Text>
            <Text mb={4}>
            Despite the complexity of the case and the lack of adaptation to any specific jurisdiction or reliance on codified laws, JudgeAI analyzed all the evidence and delivered a decision that was entirely consistent with the final ruling of the human judge. 
            </Text>
            <Text mb={4}>
            This test case was documented and shared on our YouTube channel on May 16, 2024 <Link href="https://youtu.be/NAHLBM5ZndQ?si=rcBM3eCTq3WpRO2I" color={"blue"}>(Video)</Link>. The official decision for this case was published on September 12 in the Russian judicial system "kad.arbitr" and is available here <Link href="https://kad.arbitr.ru/Document/Pdf/a1b61ca2-0581-497c-84a0-60813c638978/d20ea99c-5776-48b4-bc09-6f8fff634667/A55-9942-2023_20240912_Reshenie_(rezoljutivnaja_chast).pdf?isAddStamp=True" color={"blue"}>(Decision)</Link>
            </Text>
            <Text mb={4}>
            JudgeAI’s ability to deliver such precise outcomes with minimal time and cost highlights its potential to revolutionize the judicial system and set a new standard for AI-driven legal processes.
            </Text>
            {/* <Divider borderColor={"teal"} borderWidth={"thin"}/>
            <Flex justify="space-around" mt={8}>
              <Stack align="center">
                <Heading as="h4" size="md">
                  Claimant
                </Heading>
                <Link href="/testcases/Lawsuit_Loan.pdf" download>
                  <IconButton
                      icon={<GrDocumentText size={"70px"} />}
                      aria-label="Download claimant file"
                      size="lg"
                      boxSize={"100px"}
                  />
                </Link>
              </Stack>
              <Divider orientation="vertical" height="150px" borderColor={"teal"} borderWidth={"thin"}/>
              <Stack align="center">
                <Heading as="h4" size="md">
                  Defendant
                </Heading>
                <Link href="/testcases/Response_Loan.pdf" download>
                  <IconButton
                      icon={<GrDocumentText size={"70px"} />}
                      aria-label="Download defendant file"
                      size="lg"
                      boxSize={"100px"}
                  />
                </Link>
              </Stack>
            </Flex> */}
          </Box>

          <Box ml={{ base: 2, md: 20 }} mr={{ base: 2, md: 20 }}>
          <Heading as="h3" size="lg" mb={8} textAlign={"center"} color={"blue"}>
            Elon Musk vs OpenAI
            </Heading>
            <Text mb={4}>
            In a recent mock legal exercise, JudgeAI showcased its advanced capabilities by simulating a high-profile dispute between Elon Musk (Plaintiff) and OpenAI’s co-founders, Sam Altman and Greg Brockman, along with various OpenAI entities (Defendants). The simulated case centered around Musk’s claim that OpenAI had breached its Founding Agreement. He argued that OpenAI deviated from its original mission as a non-profit, open-source organization dedicated to developing artificial general intelligence (AGI) for the public good, and transformed into a for-profit, closed-source entity prioritizing commercial interests. This shift was particularly evident in the development of GPT-4, which was kept proprietary and not openly shared with the public, conflicting with OpenAI’s founding principles.
            </Text>
            <Text mb={4}>
            JudgeAI analyzed the publicly available statement of claim and generated the defendants’ position based on accessible information. The key legal issues addressed included the restructuring of OpenAI and the strategic decisions behind GPT-4’s development. JudgeAI assessed whether these actions constituted a breach of the original agreement and principles that guided OpenAI’s formation. This mock case highlighted how JudgeAI can handle complex topics like corporate governance, intellectual property, and the alignment of organizational missions with founding goals.
What makes this demonstration particularly notable is that JudgeAI, powered by OpenAI’s GPT models, was able to objectively analyze and issue a decision in a case involving its own developers. This underscores the system’s impartiality and capacity to handle potential conflicts of interest without bias. A video documenting this exercise was also published on YouTube, showcasing JudgeAI’s technological sophistication and commitment to unbiased legal analysis <Link href="https://youtu.be/HleryNW2CT0?si=6jEfHe5IYpHbdvRh" color={"blue"}>(Video)</Link>.
            </Text>
          </Box>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default UseCasesPage;
