import { useEffect } from "react";
import {
  ChakraProvider,
  theme,
  Heading,
  Box,
  Text,
  Grid,
  Stack,
  Flex,
  Divider,
  Button,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import ReactGA from 'react-ga4';

export const DocumentationPage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/test_cases", title: "TestCasesPage" });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Box p={4}>
        <Heading as="h1" size="4xl" color="teal" textAlign="center" mb={{ base: 20, md: 20 }} mt={10}>
          Documentation
        </Heading>

        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={8}>
          <Box ml={{ base: 2, md: 20 }} mr={{ base: 2, md: 20 }} 
          // border={"2px solid red"}
          >
            <Heading as="h3" size="lg" mb={8} textAlign={"center"} color={"blue"}>
              JudgeAI Arbitration Rules
            </Heading>
            <Text mb={4}>
            A detailed protocol governing how the JudgeAI platform handles automated arbitration, including general provisions, the use of legal algorithms, confidentiality, submission of evidence, analysis, decision-making, procedural deadlines, and enforcement.
            </Text>
            <Divider borderColor={"teal"} borderWidth={"thin"}/>
            <Flex justify="space-around" mt={8}>
              <Stack align="center">
              <Button colorScheme="teal" as={"a"} href="arbitration_rules">JudgeAI Arbitration Rules</Button>
              </Stack>
            </Flex>
          </Box>

          <Box ml={{ base: 2, md: 20 }} mr={{ base: 2, md: 20 }}>
          <Heading as="h3" size="lg" mb={8} textAlign={"center"} color={"blue"}>
            Arbitration Clause with JudgeAI
            </Heading>
            <Text mb={4}>
            JudgeAI’s arbitration system aligns with the New York Convention by offering a transparent, bias-free process through static legal algorithms. Parties can agree on a specific algorithm version, ensuring predictable outcomes without concerns about tribunal independence or evolving data. 
            </Text>
            <Divider borderColor={"teal"} borderWidth={"thin"}/>
            <Flex justify="space-around" mt={8}>
              <Stack align="center">
              <Button colorScheme="teal" as={"a"} href="arbitration_clause">Arbitration Clause with JudgeAI</Button>
              </Stack>
            </Flex>
          </Box>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default DocumentationPage;
