import { useEffect } from "react";
import {
  ChakraProvider,
  theme,
  Heading,
  Box,
  Text,
  Grid,
  VStack,
  HStack,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import ReactGA from 'react-ga4';

export const ArbiClausePage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/test_cases", title: "TestCasesPage" });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Box p={4}>
        {/* <Heading as="h1" size="4xl" color="teal" textAlign="center" mb={{ base: 20, md: 20 }} mt={10}>
          Test Cases
        </Heading> */}

        <Grid minH="100vh" p={0} justifyContent="center">
        <Box w={["90vw", "60vw", "50vw"]} mt={10}>
        {/* <Box> */}
          <VStack spacing={4}>
              <HStack w="full" alignItems={"center"}>
                {/* <IconButton
                  as="a"
                  href="/"
                  icon={<ArrowBackIcon />}
                  aria-label="Back to home"
                  variant="ghost"
                  size="lg"
                /> */}
                <Heading as="h1" size="2xl">
                Arbitration Clause with JudgeAI
                </Heading>
              </HStack>
            {/* <Text w={"100%"} textAlign={"left"}>
              Effective Date: 20 October, 2024
            </Text> */}
            {/* <Box mb={10}> */}
            <Text>
            When drafting an arbitration clause, ensuring compliance with the New York Convention on the Recognition and Enforcement of Foreign Arbitral Awards is essential. One of the key advantages of using JudgeAI in arbitration is its ability to deliver a fully transparent and predictable process, which is crucial for meeting the Convention's requirements.
            </Text>
            <Text>
            Unlike systems that rely on constantly changing data, which can raise concerns about the independence of the tribunal, JudgeAI’s decisions are based on static legal algorithms. The parties can pre-agree on the specific version of the algorithm, making the process more predictable and mitigating risks related to bias. This simplifies issues around tribunal composition, as the agreed-upon algorithm can be viewed similarly to selecting a specific arbitrator in traditional arbitration.
            </Text>
            <Text>
            To ensure compliance with the Convention, the arbitration clause should clearly specify the use of JudgeAI and its algorithms. This will guarantee a transparent, stable decision-making process without the influence of external factors or evolving data.
            </Text>
            <Text>
            Sample Arbitration Clause: <br />
            <Text as={"i"}>
"All disputes arising out of or in connection with this agreement shall be resolved using the automated arbitration system JudgeAI (algorithm version J1.0, LLM version gpt-4o-2024-08-06). The parties agree that the arbitration process will be based on pre-programmed legal algorithms without the use of pre-trained data. The arbitration procedure shall ensure equal opportunities for the presentation of evidence and proper notification to all parties."
</Text>
            </Text>
            <Text>
            This clause minimizes risks related to tribunal composition and potential disputes about bias or independence, ensuring full compliance with the New York Convention.
            </Text>
          {/* </Box> */}


          </VStack>
        </Box>
        </Grid>

      </Box>
    </ChakraProvider>
  );
}

export default ArbiClausePage;
