// theme.js
import { extendTheme } from "@chakra-ui/react";

// Define your custom theme
const theme = extendTheme({
  config: {
    initialColorMode: "light",  // Always start in light mode
    useSystemColorMode: false,  // Ignore the system color mode setting
  },
  // You can add other theme customizations here, like colors, fonts, etc.
});

export default theme;
