// import { useState, useEffect, ChangeEvent, useCallback } from "react";
// import {
//   ChakraProvider,
//   theme,
//   Box,
//   VStack,
//   Button,
//   Heading,
//   Input,
//   InputGroup,
//   InputRightElement,
//   useToast,
//   Text,
//   Spinner,
//   List,
//   ListItem,
//   Divider,
//   Badge,
// } from "@chakra-ui/react";
// import Navbar from "../components/Navbar";
// import { useUserAuth } from "../context/userAuthContext";
// import { db } from "../firebase";
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   setDoc,
//   doc,
//   Timestamp,
//   getDoc,
//   updateDoc,
// } from "firebase/firestore";
// import { useNavigate } from "react-router-dom";

// type Invitation = {
//   id: string;
//   inviter: string;
//   invitee: string;
//   status: "pending" | "accepted" | "rejected";
//   timestamp: Timestamp;
// };


// export const DisputeInvitationPage: React.FC = () => {
//   const { user } = useUserAuth();
//   const navigate = useNavigate();

//   const [searchInput, setSearchInput] = useState<string>("");
//   const [sentInvitations, setSentInvitations] = useState<any[]>([]);
//   const [receivedInvitations, setReceivedInvitations] = useState<any[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const toast = useToast();



//   const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
//     setSearchInput(e.target.value);
//   };

//   // Memoized function to fetch invitations
//   const fetchInvitations = useCallback(async () => {
//     if (!user) return;
//     const userEmail = user.email || "";

//     try {
//       // Query for sent invitations
//       const sentQuery = query(collection(db, "multi_invites"), where("inviter", "==", userEmail));
//       const sentSnapshot = await getDocs(sentQuery);
//       const fetchedSentInvitations = sentSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       })) as Invitation[];
//       setSentInvitations(fetchedSentInvitations);

//       // Query for received invitations
//       const receivedQuery = query(
//         collection(db, "multi_invites"),
//         where("invitee", "==", userEmail)
//       );
//       const receivedSnapshot = await getDocs(receivedQuery);
//       const fetchedReceivedInvitations = receivedSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       })) as Invitation[];
//       setReceivedInvitations(fetchedReceivedInvitations);
//     } catch (error) {
//       console.error("Error fetching invitations: ", error);
//       toast({
//         title: "Error.",
//         description: "Failed to fetch invitations.",
//         status: "error",
//         duration: 3000,
//         isClosable: true,
//       });
//     } finally {
//       setLoading(false);
//     }
//   }, [user, toast]);

//   // Fetch invitations when the component mounts or when `user` changes
//   useEffect(() => {
//     fetchInvitations();
//   }, [fetchInvitations]);

//   const handleInvite = async () => {
//     if (!user) return;
//     const inviterEmail = user.email || "";
//     const inviteeEmail = searchInput;

//     if (inviterEmail === inviteeEmail) {
//       toast({
//         title: "Invalid Invitation.",
//         description: "You cannot invite yourself.",
//         status: "error",
//         duration: 3000,
//         isClosable: true,
//       });
//       return;
//     }

//     try {
//       const invitationRef = doc(db, "multi_invites", `${inviterEmail}_${inviteeEmail}`);
//       const invitationDoc = await getDoc(invitationRef);

//       if (invitationDoc.exists()) {
//         toast({
//           title: "Invitation Pending.",
//           description: `An invitation is already pending for ${inviteeEmail}.`,
//           status: "info",
//           duration: 3000,
//           isClosable: true,
//         });
//       } else {
//         await setDoc(invitationRef, {
//           inviter: inviterEmail,
//           invitee: inviteeEmail,
//           status: "pending",
//           timestamp: Timestamp.now(),
//         });

//         toast({
//           title: "Invitation Sent.",
//           description: `Invitation sent to ${inviteeEmail}.`,
//           status: "success",
//           duration: 3000,
//           isClosable: true,
//         });

//         fetchInvitations(); // Refresh invitations
//       }
//     } catch (error) {
//       console.error("Error sending invitation: ", error);
//       toast({
//         title: "Error.",
//         description: "Failed to send the invitation.",
//         status: "error",
//         duration: 3000,
//         isClosable: true,
//       });
//     }
//   };

//   const handleAcceptInvitation = async (invitationId: string) => {
//     try {
//       const invitationRef = doc(db, "multi_invites", invitationId);
//       await updateDoc(invitationRef, { status: "accepted" });

//       toast({
//         title: "Invitation Accepted.",
//         description: "You have accepted the invitation.",
//         status: "success",
//         duration: 3000,
//         isClosable: true,
//       });

//       fetchInvitations(); // Refresh invitations
//     } catch (error) {
//       console.error("Error accepting invitation: ", error);
//       toast({
//         title: "Error.",
//         description: "Failed to accept the invitation.",
//         status: "error",
//         duration: 3000,
//         isClosable: true,
//       });
//     }
//   };

//   const handleGoToDispute = (invitee: string, inviter: string) => {
//     // Navigate to dispute page with /invitee_inviter route
//     navigate(`/dispute/${inviter}/${invitee}`);
//   };

//   return (
//     <ChakraProvider theme={theme}>
//       <Navbar />
//       <Box p={4}>
//         <VStack spacing={8} align="center" mt="10vh">
//           <Heading as="h1" size="xl" textAlign="center">
//             Invite a User to Dispute
//           </Heading>

//           <InputGroup size="lg" w="50%">
//             <Input
//               placeholder="Enter user email..."
//               value={searchInput}
//               onChange={handleSearchChange}
//             />
//             <InputRightElement width="4.5rem">
//               <Button h="1.75rem" size="sm" onClick={handleInvite} colorScheme="teal">
//                 Invite
//               </Button>
//             </InputRightElement>
//           </InputGroup>

//           <Heading as="h2" size="lg" textAlign="center" mt={10}>
//             Sent Invitations
//           </Heading>
//           {loading ? (
//             <Spinner />
//           ) : (
//             <List spacing={4} w="50%">
//               {sentInvitations.length > 0 ? (
//                 sentInvitations.map((invitation) => (
//                   <ListItem key={invitation.id}>
//                     <Box p={4} borderWidth="1px" borderRadius="md" shadow="sm">
//                       <Text><b>Invitee:</b> {invitation.invitee}</Text>
//                       <Text>
//                         <b>Status:</b>{" "}
//                         <Badge colorScheme={invitation.status === "pending" ? "yellow" : "green"}>
//                           {invitation.status}
//                         </Badge>
//                       </Text>
//                       <Text><b>Sent On:</b> {invitation.timestamp.toDate().toLocaleString()}</Text>
//                       {invitation.status === "accepted" && (
//                       <Button
//                         mt={2}
//                         colorScheme="teal"
//                         size="sm"
//                         onClick={() =>
//                           handleGoToDispute(invitation.invitee, invitation.inviter)
//                         }
//                       >
//                         Go to Dispute
//                       </Button>
//                     )}
//                     </Box>
//                     <Divider />
//                   </ListItem>
//                 ))
//               ) : (
//                 <Text>No invitations sent yet.</Text>
//               )}
//             </List>
//           )}

//           <Heading as="h2" size="lg" textAlign="center" mt={10}>
//             Received Invitations
//           </Heading>
//           {loading ? (
//             <Spinner />
//           ) : (
//             <List spacing={4} w="50%">
//               {receivedInvitations.length > 0 ? (
//                 receivedInvitations.map((invitation) => (
//                   <ListItem key={invitation.id}>
//                     <Box p={4} borderWidth="1px" borderRadius="md" shadow="sm">
//                       <Text><b>Inviter:</b> {invitation.inviter}</Text>
//                       <Text>
//                         <b>Status:</b>{" "}
//                         <Badge colorScheme={invitation.status === "pending" ? "yellow" : "green"}>
//                           {invitation.status}
//                         </Badge>
//                       </Text>
//                       {invitation.status === "accepted" && (
//                       <Button
//                         mt={2}
//                         colorScheme="teal"
//                         size="sm"
//                         onClick={() =>
//                           handleGoToDispute(invitation.invitee, invitation.inviter)
//                         }
//                       >
//                         Go to Dispute
//                       </Button>
//                     )}
//                     {invitation.status === "pending" && (
//                       <Button
//                         mt={2}
//                         colorScheme="teal"
//                         size="sm"
//                         onClick={() => handleAcceptInvitation(invitation.id)}
//                         isDisabled={invitation.status !== "pending"}
//                       >
//                         Accept
//                       </Button>)}
//                     </Box>
//                     <Divider />
//                   </ListItem>
//                 ))
//               ) : (
//                 <Text>No invitations received yet.</Text>
//               )}
//             </List>
//           )}
//         </VStack>
//       </Box>
//     </ChakraProvider>
//   );
// };

// export default DisputeInvitationPage;

import { useState, useEffect, ChangeEvent, useCallback } from "react";
import {
  ChakraProvider,
  theme,
  Box,
  VStack,
  Button,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Text,
  Spinner,
  List,
  ListItem,
  Divider,
  Badge,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import { useUserAuth } from "../context/userAuthContext";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  Timestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

type Invitation = {
  id: string;
  inviter: string;
  invitee: string;
  status: "pending" | "accepted" | "rejected";
  timestamp: Timestamp;
  invite_number: string;
};

export const DisputeInvitationPage: React.FC = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState<string>("");
  const [sentInvitations, setSentInvitations] = useState<any[]>([]);
  const [receivedInvitations, setReceivedInvitations] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const toast = useToast();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  // Memoized function to fetch invitations
  const fetchInvitations = useCallback(async () => {
    if (!user) return;
    const userEmail = user.email || "";

    try {
      // Query for sent invitations
      const sentQuery = query(collection(db, "multi_invites"), where("inviter", "==", userEmail));
      const sentSnapshot = await getDocs(sentQuery);
      const fetchedSentInvitations = sentSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Invitation[];
      setSentInvitations(fetchedSentInvitations);

      // Query for received invitations
      const receivedQuery = query(
        collection(db, "multi_invites"),
        where("invitee", "==", userEmail)
      );
      const receivedSnapshot = await getDocs(receivedQuery);
      const fetchedReceivedInvitations = receivedSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Invitation[];
      setReceivedInvitations(fetchedReceivedInvitations);
    } catch (error) {
      console.error("Error fetching invitations: ", error);
      toast({
        title: "Error.",
        description: "Failed to fetch invitations.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [user, toast]);

  // Fetch invitations when the component mounts or when `user` changes
  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations]);

  const handleInvite = async () => {
    if (!user) return;
    const inviterEmail = user.email || "";
    const inviteeEmail = searchInput;

    if (inviterEmail === inviteeEmail) {
      toast({
        title: "Invalid Invitation.",
        description: "You cannot invite yourself.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      // Query to get existing invitations
      const existingInvitesQuery = query(
        collection(db, "multi_invites"),
        where("inviter", "==", inviterEmail),
        where("invitee", "==", inviteeEmail)
      );
      const existingInvitesSnapshot = await getDocs(existingInvitesQuery);
      const numExistingInvitations = existingInvitesSnapshot.size;

      // Create new invitation ID with counter
      const invitationRef = doc(db, "multi_invites", `${inviterEmail}_${inviteeEmail}_${numExistingInvitations + 1}`);
      const invitationDoc = await getDoc(invitationRef);

      if (invitationDoc.exists()) {
        toast({
          title: "Invitation Pending.",
          description: `An invitation is already pending for ${inviteeEmail}.`,
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await setDoc(invitationRef, {
          inviter: inviterEmail,
          invitee: inviteeEmail,
          status: "pending",
          timestamp: Timestamp.now(),
          invite_number: `${numExistingInvitations + 1}`,
        });

        toast({
          title: "Invitation Sent.",
          description: `Invitation sent to ${inviteeEmail}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        fetchInvitations(); // Refresh invitations
      }
    } catch (error) {
      console.error("Error sending invitation: ", error);
      toast({
        title: "Error.",
        description: "Failed to send the invitation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAcceptInvitation = async (invitationId: string) => {
    try {
      const invitationRef = doc(db, "multi_invites", invitationId);
      await updateDoc(invitationRef, { status: "accepted" });

      toast({
        title: "Invitation Accepted.",
        description: "You have accepted the invitation.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      fetchInvitations(); // Refresh invitations
    } catch (error) {
      console.error("Error accepting invitation: ", error);
      toast({
        title: "Error.",
        description: "Failed to accept the invitation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleGoToDispute = (invitee: string, inviter: string, id:string) => {
    navigate(`/dispute/${inviter}/${invitee}/${id}`);
  };

  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Box p={4}>
        <VStack spacing={8} align="center" mt="10vh">
          <Heading as="h1" size="xl" textAlign="center">
            Invite a User to Dispute
          </Heading>

          <InputGroup size="lg" w="50%">
            <Input
              placeholder="Enter user email..."
              value={searchInput}
              onChange={handleSearchChange}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleInvite} colorScheme="teal">
                Invite
              </Button>
            </InputRightElement>
          </InputGroup>

          {/* Render sent and received invitations lists */}
          {/* Sent Invitations */}
          <Heading as="h2" size="lg" textAlign="center" mt={10}>
            Sent Invitations
          </Heading>
          {loading ? (
            <Spinner />
          ) : (
            <List spacing={4} w="50%">
              {sentInvitations.length > 0 ? (
                sentInvitations.map((invitation) => (
                  <ListItem key={invitation.id}>
                    <Box p={4} borderWidth="1px" borderRadius="md" shadow="sm">
                      <Text><b>Invitee:</b> {invitation.invitee}</Text>
                      <Text>
                        <b>Status:</b>{" "}
                        <Badge colorScheme={invitation.status === "pending" ? "yellow" : "green"}>
                          {invitation.status}
                        </Badge>
                      </Text>
                      <Text><b>Invitation ID:</b> {invitation.invite_number}</Text>
                      <Text><b>Sent On:</b> {invitation.timestamp.toDate().toLocaleString()}</Text>
                      {invitation.status === "accepted" && (
                      <Button
                        mt={2}
                        colorScheme="teal"
                        size="sm"
                        onClick={() =>
                          handleGoToDispute(invitation.invitee, invitation.inviter, invitation.invite_number)
                        }
                      >
                        Go to Dispute
                      </Button>
                    )}
                    </Box>
                    <Divider />
                  </ListItem>
                ))
              ) : (
                <Text>No invitations sent yet.</Text>
              )}
            </List>
          )}

          {/* Received Invitations */}
          <Heading as="h2" size="lg" textAlign="center" mt={10}>
            Received Invitations
          </Heading>
          {loading ? (
            <Spinner />
          ) : (
            <List spacing={4} w="50%">
              {receivedInvitations.length > 0 ? (
                receivedInvitations.map((invitation) => (
                  <ListItem key={invitation.id}>
                    <Box p={4} borderWidth="1px" borderRadius="md" shadow="sm">
                      <Text><b>Inviter:</b> {invitation.inviter}</Text>
                      <Text>
                        <b>Status:</b>{" "}
                        <Badge colorScheme={invitation.status === "pending" ? "yellow" : "green"}>
                          {invitation.status}
                        </Badge>
                      </Text>
                      <Text><b>Invitation ID:</b> {invitation.invite_number}</Text>
                      <Text><b>Received On:</b> {invitation.timestamp.toDate().toLocaleString()}</Text>
                      {invitation.status === "pending" && (
                        <Button
                          mt={2}
                          colorScheme="teal"
                          size="sm"
                          onClick={() => handleAcceptInvitation(invitation.id)}
                        >
                          Accept
                        </Button>
                      )}
                      {invitation.status === "accepted" && (
                        <Button
                          mt={2}
                          colorScheme="teal"
                          size="sm"
                          onClick={() =>
                            handleGoToDispute(invitation.invitee, invitation.inviter, invitation.invite_number)
                          }
                        >
                          Go to Dispute
                        </Button>
                      )}
                    </Box>
                    <Divider />
                  </ListItem>
                ))
              ) : (
                <Text>No invitations received yet.</Text>
              )}
            </List>
          )}
        </VStack>
      </Box>
    </ChakraProvider>
  );
};

export default DisputeInvitationPage;