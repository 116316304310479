import { useState, useEffect, ChangeEvent } from "react";
import {
  ChakraProvider,
  theme,
  Box,
  VStack,
  Button,
  Heading,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import UploadButtonSimple from "../components/UploadButtonSimple";
import axios from 'axios';
import Markdown from "react-markdown";
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import ReactMarkdown from "react-markdown";
// import { useUserAuth } from "../context/userAuthContext";
// import { db, storage } from '../firebase';
// import { doc, getDoc, updateDoc } from "firebase/firestore";
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ReactGA from 'react-ga4';


export const SimpleJudgementPage = () => {
  // const { user } = useUserAuth();

  const [stage1Files, setStage1Files] = useState<File[]>([]);
  const [stage1Output, setStage1Output] = useState<string>("");
  const [stage1SubmitLoading, setStage1SubmitLoading] = useState(false)
  const [stage1SubmitDisabled, setStage1SubmitDisabled] = useState(false)
  const [stage1FileUploading, setStage1FileUploading] = useState(false)

  const [showStage2, setShowStage2] = useState(false)
  const [stage2Files, setStage2Files] = useState<File[]>([]);
  const [stage2Output, setStage2Output] = useState<string>("");
  const [stage2SubmitLoading, setStage2SubmitLoading] = useState(false)
  const [stage2SubmitDisabled, setStage2SubmitDisabled] = useState(false)
  const [stage2FileUploading, setStage2FileUploading] = useState(false)
  
  const [stage3Output, setStage3Output] = useState<string>("");

  const [claimExtractedText, setClaimExtractedText] = useState<string>("");

  // const [userCredits, setUserCredits] = useState(0);
  // const [userEmail, setUserEmail] = useState("");
  // const [loadingScreen, setLoadingScreen] = useState(true);

  const [claimText, setClaimText] = useState('')
  const [defendantText, setDefendantText] = useState("")

  let handleClaimTextInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let inputValue = e.target.value
    setClaimText(inputValue)
    // console.log(claimText)
  }

  let handleDefendantTextInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let inputValue = e.target.value
    setDefendantText(inputValue)
  }

  const toast = useToast()

  function addToast() {
    // toast({ description: 'some text' })
    toast({
      title: 'JudgeAI is analysing facts',
      description: "JudgeAI typically takes a minute to respond",
      status: 'loading',
      duration: null,
      // isClosable: true,
    })
  }

  function closeAll() {
    toast.closeAll()
  }
  
  // useEffect for Google Analytics pageview tracking
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/judgement", title: "JudgementPage" });
  }, []); // Empty dependency array means this runs only on mount

  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     if (user) { 
  //       const userEmail = user.email || '';
  //       setUserEmail(userEmail);
          
  //       try {
  //           const userDoc = await getDoc(doc(db, "users", userEmail));
  //           if (userDoc.exists()) {
  //           const userData = userDoc.data();
  //           setUserCredits(userData.credits || 0);
  //           setLoadingScreen(false)
  //           } else {
  //           console.log("No such document!");
  //           }
  //       } catch (error) {
  //           console.error("Error fetching user data: ", error);
  //       }
  //       }
  //   };

  //   // ReactGA.send({ hitType: "pageview", page: "/judgement", title: "JudgementPage" });
  //   fetchUserData();
  //   }, [user]);


  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  useEffect(() => {
    if (stage1Output) {
      scrollToSection("bottom");
    }
  }, [stage1Output]);
  
  useEffect(() => {
    if (stage2Output) {
      scrollToSection("bottom");
    }
  }, [stage2Output]);
  
  useEffect(() => {
    if (stage3Output) {
      scrollToSection("bottom");
    }
  }, [stage3Output]);


  // const uploadFilesToFirebase = async (files: File[], stage: number) => {
  //   const uploadedFiles: { fileName: string; url: string }[] = [];

  //   for (const file of files) {
  //     const fileRef = ref(storage, `judgement_${userEmail}_${stage}_${file.name}`);
  //     await uploadBytes(fileRef, file);
  //     const url = await getDownloadURL(fileRef);
  //     uploadedFiles.push({ fileName: file.name, url });
  //   }

  //   return uploadedFiles;
  // };


  const handleStage1FilesChange = async (files: File[]) => {
    setStage1SubmitDisabled(true);
    setStage1SubmitLoading(true);
    setStage1FileUploading(true);
    
    setStage1Files(files);
    
      setStage1SubmitDisabled(false);
      setStage1SubmitLoading(false);
      setStage1FileUploading(false);
    
  };

  const handleStage2FilesChange = async (files: File[]) => {
    setStage2SubmitDisabled(true);
    setStage2SubmitLoading(true);
    setStage2FileUploading(true);
  
      setStage2Files(files);
    
      setStage2SubmitDisabled(false);
      setStage2SubmitLoading(false);
      setStage2FileUploading(false);
    
  };
  
  

  const handleSubmitStage1 = async () => {
    addToast()
    setStage1SubmitLoading(true)


    if (stage1Files.length > 0) {
      const formData = new FormData();
      stage1Files.forEach((file) => {
        formData.append('stage1files', file);  // Use 'files' as the key to send multiple files
      });

      try {
        const response = await axios.post('https://judgeaiv0.azurewebsites.net/simple_claim_extraction/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setClaimExtractedText(response.data.claim_extracted_text)

        setStage1SubmitLoading(false)
        setShowStage2(true)

        scrollToSection("bottom")
      } catch (error) {
        console.error('Error uploading the files', error);
      }
    } else {
      console.log('No files to upload');
    }
    setStage1SubmitLoading(false)
    setShowStage2(true)
    setStage1SubmitDisabled(true)
    closeAll()
  };

  const handleSubmitStage2 = async () => {
    addToast()
    setStage2SubmitLoading(true);
    const formData = new FormData();
    if (stage2Files.length > 0) {
      stage2Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });
    }
    formData.append('input_string', ("\nPlaintiff Facts and Evidence: \n\n" + claimText + claimExtractedText + "\nDefendant Facts and Evidence: \n\n" + defendantText));  
    try {
      const response = await axios.post('https://judgeaiv0.azurewebsites.net/simple_stage_1/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setStage1Output(response.data.response);
      // setResponseInfo(response.data.response_info)
      // setStage2SubmitLoading(false);
      setStage2SubmitDisabled(true);

      const stage2Input = "\nPlaintiff Facts and Evidence: \n\n" + claimText + claimExtractedText + 
                        "\nDefendant Facts and Evidence: \n\n" + defendantText + response.data.defendant_extracted_text + 
                        "\nAnalysis: \n\n" + response.data.response

      const response2 = await axios.post('https://judgeaiv0.azurewebsites.net/simple_stage_2/', stage2Input, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setStage2Output(response2.data.response)

      const stage3Input = "\nPlaintiff Facts and Evidence: \n\n" + claimText + claimExtractedText + 
                        "\nDefendant Facts and Evidence: \n\n" + defendantText + response.data.defendant_extracted_text + 
                        "\nAnalysis: \n\n" + response.data.response + "\nMore Analysis: \n\n" + response2.data.response

      const response3 = await axios.post('https://judgeaiv0.azurewebsites.net/simple_stage_3/', stage3Input, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setStage3Output(response3.data.response)
      setStage2SubmitLoading(false);
      // scrollToSection("bottom")

    } catch (error) {
      console.error('Error uploading the files', error);
      setStage2SubmitLoading(false);
    }
    closeAll()
  };


  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Box paddingTop={"40px"} paddingBottom={"40px"}>
        <VStack spacing={"40px"}>
        <Box>
          <VStack spacing={"10px"}>
          <Heading size={"lg"} mb={4}>Upload Claim by Claimant</Heading>
          <Textarea 
            height={"30vh"} 
            placeholder='Type your Claim or attach a file' 
            value={claimText}
            onChange={handleClaimTextInputChange}
          />
          <UploadButtonSimple onFilesChange={handleStage1FilesChange} isDisabled={(stage1SubmitDisabled || stage1SubmitLoading)} uploading={stage1FileUploading} />
          <Button colorScheme="teal" onClick={handleSubmitStage1} isDisabled={stage1SubmitDisabled} isLoading={stage1SubmitLoading}>
            Submit
          </Button>
          </VStack>
        </Box>
            {showStage2 && (
            <Box
            mt={4}
            >
              <VStack spacing={"10px"}>
              <Heading size={"lg"} mb={4}>Upload Response by defendant</Heading>
              <Textarea 
                height={"30vh"} 
                placeholder='Type your Response or attach a file' 
                value={defendantText}
                onChange={handleDefendantTextInputChange}
              />
                <UploadButtonSimple onFilesChange={handleStage2FilesChange} 
                              message="Attach additional file" 
                              isDisabled = {stage2SubmitDisabled || stage2SubmitLoading}
                              uploading={stage2FileUploading}
                              />
                <Button colorScheme="teal" onClick={handleSubmitStage2} isDisabled={stage2SubmitDisabled} isLoading={stage2SubmitLoading}>
                  Submit
                </Button>
              </VStack>
            </Box>
          )}
          {stage1Output && (
            <>
            <Box
              p={10}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Analysis of arguments</Heading>
              <ReactMarkdown components={ChakraUIRenderer()} children={stage1Output} skipHtml />;
            </Box>
            </>
          )}

          {stage2Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Detailed Analysis</Heading>
              <ReactMarkdown components={ChakraUIRenderer()} children={stage2Output} skipHtml />;
            </Box>
            </>
          )}

          {stage3Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Final Judgement</Heading>
              <Markdown>{stage3Output}</Markdown>
            </Box>
            </>
          )}
        </VStack>
        <div id="bottom">
        </div>
      </Box>
    </ChakraProvider>
  );
};

export default SimpleJudgementPage;