// import {
//     Box,
//     Button,
//     Drawer,
//     DrawerOverlay,
//     DrawerCloseButton,
//     DrawerHeader,
//     DrawerBody,
//     DrawerContent,
//     VStack,
//     Text,
//   } from '@chakra-ui/react'
  
//   interface Props {
//     onClose: () => void
//     isOpen: boolean
//     variant: 'drawer' | 'sidebar'
//     url1: string
//     url2: string
//     url3: string
//     url4: string
//   }
  
//   const SidebarContent = ({ onClick, url1, url2, url3, url4 }: { onClick: () => void, url1: string, url2: string, url3: string, url4: string }) => {
//     const urls = [url1, url2, url3, url4].filter(url => url !== "")
  
//     return (
//       <VStack>
//         {urls.length > 0 ? (
//           urls.map((url, index) => (
//             <Button as="a" href={url} key={index} w="100%" target="_blank" rel="noopener noreferrer">
//               File {index + 1}
//             </Button>
//           ))
//         ) : (
//           <Text>No files uploaded</Text>
//         )}
//         {/* <Button onClick={onClick} w="100%">
//           Home
//         </Button>
//         <Button onClick={onClick} w="100%">
//           About
//         </Button>
//         <Button onClick={onClick} w="100%">
//           Contact
//         </Button> */}
//       </VStack>
//     )
//   }
  
//   const Sidebar = ({ isOpen, variant, onClose, url1, url2, url3, url4 }: Props) => {
//     return variant === 'sidebar' ? (
//       <Box
//         position="fixed"
//         left={0}
//         p={5}
//         w="200px"
//         top={0}
//         h="100%"
//         bg="#dfdfdf"
//       >
//         <SidebarContent onClick={onClose} url1={url1} url2={url2} url3={url3} url4={url4} />
//       </Box>
//     ) : (
//       <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
//         <DrawerOverlay>
//           <DrawerContent>
//             <DrawerCloseButton />
//             <DrawerHeader>Chakra-UI</DrawerHeader>
//             <DrawerBody>
//               <SidebarContent onClick={onClose} url1={url1} url2={url2} url3={url3} url4={url4} />
//             </DrawerBody>
//           </DrawerContent>
//         </DrawerOverlay>
//       </Drawer>
//     )
//   }
  
//   export default Sidebar
  
import {
    Box,
    Button,
    Drawer,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerContent,
    VStack,
    // Text,
    // Alert
  } from '@chakra-ui/react';
  
  interface Props {
    onClose: () => void;
    isOpen: boolean;
    variant: 'drawer' | 'sidebar';
    url1: string;
    url2: string;
    url3: string;
    url4: string;
  }
  
  const SidebarContent = ({
    onClick,
    url1,
    url2,
    url3,
    url4,
  }: {
    onClick: () => void;
    url1: string;
    url2: string;
    url3: string;
    url4: string;
  }) => {
    const urls = [url1, url2, url3, url4].filter(url => url !== '');

    // const handleNoFilesUploaded = () => {
    //     alert("No files uploaded!");
    //   };
    
  
    return (
      <VStack>
        {urls.length > 0 ? (
          urls.map((url, index) => (
            <Button
              as="a"
              href={url}
              key={index}
              w="100%"
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClick} // Close sidebar when file is clicked
            >
              {/* File {index + 1} */}
              {index === 0 ? 'Claim' 
              : index === 1 ? 'Response' 
              : index === 2 ? 'Claim Response' 
              : 'Defendant Response'}
            </Button>
          ))
        ) : (
        //   <Text>No <br /> files <br /> 
        //     uploaded</Text>
        // <Alert status='error'>
        //     no file uploaded
        // </Alert>
        <Box></Box>
        )}
      </VStack>
    );
  };
  
  const Sidebar = ({
    isOpen,
    variant,
    onClose,
    url1,
    url2,
    url3,
    url4,
  }: Props) => {
    return variant === 'sidebar' ? (
      <Box
        position="fixed"
        left={0}
        p={5}
        w="200px"
        top={120}
        h="100%"
        // bg="#dfdfdf"
      >
        <SidebarContent onClick={onClose} url1={url1} url2={url2} url3={url3} url4={url4} />
      </Box>
    ) : (
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton onClick={onClose} />
            <DrawerHeader>Chakra-UI</DrawerHeader>
            <DrawerBody>
              <SidebarContent onClick={onClose} url1={url1} url2={url2} url3={url3} url4={url4} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  };
  
  export default Sidebar;
  